<template>
    <div class="mm_cards p-3">
        <h3> {{ $t('message.keyIndicators') }} </h3>
        <template>
            <el-table
            :data="list"
            style="width: 100%">
                <el-table-column
                    prop="name"
                    :label="$t('message.name')">
                </el-table-column>
                <el-table-column
                    prop="fact"
                    :label="$t('message.fact')">
                </el-table-column>
                <el-table-column
                    prop="plan"
                    :label="$t('message.plan')">
                </el-table-column>
                <el-table-column
                    prop="performance"
                    :label="$t('message.performance')">
                    <template slot-scope="scope">
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="scope.row.percentage"></el-progress>
                    </template>
                </el-table-column>
            </el-table>
        </template>

    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import * as echarts from 'echarts/core';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from 'echarts/components';
    import { GaugeChart } from 'echarts/charts';
    import { LabelLayout } from 'echarts/features';
    import { CanvasRenderer } from 'echarts/renderers';
    import VChart, { THEME_KEY } from "vue-echarts";

    echarts.use([
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        GaugeChart,
        CanvasRenderer,
        LabelLayout
    ]);
  export default {
      name: "fact_panel",
      components: {
        VChart
      },

      provide() {
        return {
            [THEME_KEY]: "dark"
        };
      },
  
      data() {
          return {
          };
      },

      created() {
        this.updateList({period: 'yearly'})
            .then(res => {
            })
            .catch(err => {
            })
      },
  
      computed: {
          ...mapGetters({
              list: "kpiPanel/keyIndicators",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            updateList: "kpiPanel/keyIndicators",
        }),
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #688188;
}
.card__tablenight .mm_cards {
    background-color: #080c24;
}

</style>