<template>
    <div class="mm_cards p-3">
        <h3> {{ $t('message.productsRanking') }} </h3>
        <template>
            <el-table
            :data="new_list"
            style="width: 100%">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="name"
                    :label="$t('message.name')"
                    width="400">
                </el-table-column>
                <el-table-column
                    prop="profit"
                    :label="$t('message.profit')">
                    <template slot-scope="scope">
                        {{ scope.row.profit | formatMoney(2) }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="money_amount"
                    :label="$t('message.revenue')">
                    <template slot-scope="scope">
                        {{ scope.row.money_amount | formatMoney(2) }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('message.volume_rank')">
                    <template slot-scope="scope">
                        {{ scope.row.rank }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="quantity"
                    :label="$t('message.volume')">
                </el-table-column>
                <el-table-column
                    prop="average"
                    :label="$t('message.average')">
                    <template slot-scope="scope">
                        {{ (scope.row.money_amount / scope.row.quantity) | formatMoney(2) }}
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="percentage"
                    :label="$t('message.percentage')">
                    <template slot-scope="scope">
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="scope.row.percentage"></el-progress>
                    </template>
                </el-table-column> -->
            </el-table>
        </template>

    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import * as echarts from 'echarts/core';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from 'echarts/components';
    import { GaugeChart } from 'echarts/charts';
    import { LabelLayout } from 'echarts/features';
    import { CanvasRenderer } from 'echarts/renderers';
    import VChart, { THEME_KEY } from "vue-echarts";

    echarts.use([
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        GaugeChart,
        CanvasRenderer,
        LabelLayout
    ]);
  export default {
      name: "fact_panel",
      components: {
        VChart
      },

      provide() {
        return {
            [THEME_KEY]: "dark"
        };
      },
  
      data() {
          return {
            new_list: []
          };
      },

      created() {
        this.productSellStatisticsData({period: 'yearly'})
            .then(res => {
                let rank = JSON.parse(JSON.stringify(this.list));
                this.new_list = JSON.parse(JSON.stringify(this.list));
                rank.sort((a, b) => parseFloat(a.quantity || 0) > parseFloat(b.quantity || 0) ? -1 : 0);
                rank = rank.map(el => el.id);

                this.new_list.forEach(element => {
                    let index = rank.indexOf(element.id);
                    if(index >= 0){
                        element.rank = index + 1;
                    }
                });

            })
      },
  
      computed: {
          ...mapGetters({
              // list: "reasons/list",
              list: "home/productSellStatistics",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            productSellStatisticsData: "home/productSellStatistics",
            // updateList: "reasons/index",
        }),
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #688188;
}
.card__tablenight .mm_cards {
    background-color: #080c24;
}

</style>