<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.kpi_panel") }} </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart" :class="mode ? 'content__titleday' : 'content__titlenight'">
        
        <el-row :gutter="20" class="mt-5">
          <el-col :span="12" class="h-100">
            <KeyIndicators></KeyIndicators>
          </el-col>

          <el-col :span="12" class="h-100">
            <sellingPlanPerformanceByCountry></sellingPlanPerformanceByCountry>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="mt-5">
          <el-col :span="24">
            <productsRanking></productsRanking>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="mt-5">
          <el-col :span="12">
            <top_ten_managers></top_ten_managers>
          </el-col>
        </el-row>

        
        <el-row :gutter="20" class="mt-5">
          <el-col :span="12">
            <sellingPlanPerformanceByYear></sellingPlanPerformanceByYear>
          </el-col>

          <el-col :span="12">
            <analysisImpactVolume></analysisImpactVolume>

          </el-col>
        </el-row>

      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import KeyIndicators from './kpi_panel_components/keyIndicators'
import sellingPlanPerformanceByCountry from './kpi_panel_components/sellingPlanPerformanceByCountry'

import sellingPlanPerformanceByYear from './kpi_panel_components/sellingPlanPerformanceByYear'
import analysisImpactVolume from './kpi_panel_components/analysisImpactVolume'
import top_ten_managers from './kpi_panel_components/top_ten_managers'
import productsRanking from './kpi_panel_components/productsRanking'


export default {
    name: "fact_panel",
    components: {
      KeyIndicators,
      sellingPlanPerformanceByCountry,

      sellingPlanPerformanceByYear,
      analysisImpactVolume,
      top_ten_managers,
      productsRanking
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            // list: "reasons/list",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            // updateList: "reasons/index",
        }),
    
    },
};
</script>

<style>

.content__titlenight .cell {
  color: #fff;
}
.h-100 {
  height: 100%;
}


</style>