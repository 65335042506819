<template>
    <div>
        <div id="chart">
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import * as echarts from 'echarts/core';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from 'echarts/components';
    import { BarChart } from 'echarts/charts';
    import { LabelLayout } from 'echarts/features';
    import { CanvasRenderer } from 'echarts/renderers';
    import VChart, { THEME_KEY } from "vue-echarts";

    echarts.use([
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        BarChart,
        CanvasRenderer,
        LabelLayout
    ]);
  export default {
      name: "fact_panel",
      components: {
        VChart
      },

      provide() {
        return {
            [THEME_KEY]: "dark"
        };
      },
  
      data() {
          return {
            option: {
                title: {
                    text: this.$t('message.analysisImpactVolume')
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        let tar;
                        if (params[1] && params[1].value !== '-') {
                            tar = params[1];
                        } else {
                            tar = params[2];
                        }
                        return tar && tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
                    }
                },
                // legend: {
                //     data: ['Expenses', 'Income']
                // },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: (function () {
                        let list = [];
                        for (let i = 1; i <= 6; i++) {
                            list.push('Nov ' + i);
                        }
                        return list;
                    })()
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Placeholder',
                        type: 'bar',
                        stack: 'Total',
                        silent: true,
                        itemStyle: {
                            borderColor: 'transparent',
                            color: 'transparent'
                        },
                        emphasis: {
                            itemStyle: {
                            borderColor: 'transparent',
                            color: 'transparent'
                            }
                        },
                        data: [0, 900, 1245, 1530, 1376, 1225]
                    },
                    {
                        name: 'Income',
                        type: 'bar',
                        stack: 'Total',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        data: [900, 345, 393, '-', '-', 555]
                    },
                    {
                        name: 'Expenses',
                        type: 'bar',
                        stack: 'Total',
                        label: {
                            show: true,
                            position: 'bottom'
                        },
                        data: ['-', '-', '-', 108, 154, '-']
                    }
                ]
            }
          };
      },
  
      computed: {
          ...mapGetters({
              // list: "reasons/list",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            // updateList: "reasons/index",
        }),
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #688188;
}
.card__tablenight .mm_cards {
    background-color: #080c24;
}

</style>