<template>
    <div>
        <div id="chart">
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import * as echarts from 'echarts/core';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from 'echarts/components';
    import { LineChart } from 'echarts/charts';
    import { LabelLayout } from 'echarts/features';
    import { CanvasRenderer } from 'echarts/renderers';
    import VChart, { THEME_KEY } from "vue-echarts";

    echarts.use([
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        LineChart,
        CanvasRenderer,
        LabelLayout
    ]);
  export default {
      name: "fact_panel",
      components: {
        VChart
      },

      provide() {
        return {
            [THEME_KEY]: "dark"
        };
      },
  
      data() {
          return {
            lineData: [],
            option: {
                title: {
                    text: this.$t('message.sellingPlanPerformanceByYear')
                },
                legend: {
                    top: 'bottom',
                    data: ['Intention']
                },
                tooltip: {
                    position: function (pt) {
                     return [pt[0], 130];
                    }
                },
                xAxis: {
                    type: 'category',
                    axisPointer: {
                        snap: true,
                        lineStyle: {
                            color: '#7581BD',
                            width: 2
                        },
                        label: {
                            show: true,
                            backgroundColor: '#7581BD'
                        },
                        handle: {
                            show: true,
                            color: '#7581BD'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        inside: true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        inside: true,
                    },
                    z: 10
                },
                grid: {
                    top: 110,
                    left: 15,
                    right: 15,
                    height: 160
                },
                series: [
                    {
                        name: 'Fake Data',
                        type: 'line',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 10,
                        sampling: 'average',
                        itemStyle: {
                            color: '#0770FF'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                        data: [
                            ['yanvar', 30],
                            ['fevral', 50],
                            ['mart', 40],
                            ['aprel', 60],
                            ['may', 22],
                        ]
                    }
                ]
            }
          };
      },

      created() {
        this.generateData();
      },
  
      computed: {
          ...mapGetters({
              // list: "reasons/list",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            // updateList: "reasons/index",
        }),

        generateData() {
            let base = +new Date(2016, 9, 3);
            let oneDay = 24 * 3600 * 1000;
            let valueBase = Math.random() * 300;
            for (var i = 1; i < 10; i++) {
                var now = new Date((base += oneDay));
                var dayStr = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('-');
                valueBase = Math.round((Math.random() - 0.5) * 20 + valueBase);
                valueBase <= 0 && (valueBase = Math.random() * 300);
                this.lineData.push([dayStr, valueBase]);
            
            }
        },
        
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #688188;
}
.card__tablenight .mm_cards {
    background-color: #080c24;
}

</style>