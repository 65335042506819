<template>
    <div class="mm_cards p-3">
        <!-- <DealsByManager 
            :mode="mode" 
            @vmodel="vModel('DealsByManager', $event)"
            :allPeriodChange="radioPeriod"
            :filterForm="filterForm"
        >
        </DealsByManager> -->

        <h3> {{ $t('message.top_ten_managers') }} </h3>
        <template>
            <el-table
            :data="users"
            style="width: 100%">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="name"
                    :label="$t('message.name')">
                </el-table-column>
                <el-table-column :label="$t('message.profit')">
                    <template slot-scope="scope">
                        {{ scope.row.profit | formatMoney }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('message.revenue')">
                    <template slot-scope="scope">
                        {{ scope.row.money_amount | formatMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="percentage" :label="$t('message.percentage')">
                    <template slot-scope="scope">
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="scope.row.percentage"></el-progress>
                    </template>
                </el-table-column>
            </el-table>
        </template>

    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import DealsByManager from '@/components/dashboard/DealsByManager'

   
  export default {
      name: "fact_panel",
      components: {
        DealsByManager
      },
  
      data() {
          return {
            radioPeriod: '',
            filterForm: {},
            users: [],
          };
      },

      created() {
        this.updateUsersDeals()
        .then(res => {
            this.users = this.list.users.filter((element, index) => index < 9);
            console.log(this.list.users, 'updateUsersDeals');
        })
        .catch(err => {
        });
      },
  
      computed: {
          ...mapGetters({
              list: "home/usersDeals",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            updateUsersDeals: "home/usersDeals",

        }),
      },
  };
</script>
  
<style scoped>

.content__titlenight .cell {
  color: #688188;
}
.card__tablenight .mm_cards {
    background-color: #080c24;
}

</style>